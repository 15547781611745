.index-lists {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  width: 100%;

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    align-items: center;

    li {
      display: flex;
      justify-content: center;
      list-style: none;
      width: 100%;
      margin-bottom: 2%;
    }
  }
}

