@import "../../global.scss";

.index-list-item {
  display: flex;
  width: 100%;
  
  .list-item-left {
    background-color: rgb(223, 220, 220);
    width: 70%;

    .left-top {
      height: 80%;

      .MuiCardContent-root {
        padding: 5%;
      }
    }

    .left-bottom {
      height: 20%;

      .MuiCardActions-root {
        padding: 0;
        
        .view-list-button {
          padding-left: 5%;
          text-decoration: none;
          font-size: small;
          color: $mainColor;
        }
      }
    }
  }

  .list-item-right {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    
    img {
      object-fit: cover;
      align-self: center;
      width: 100%;
    }
  }
}