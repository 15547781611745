.MuiBox-root.css-1j8j11q {
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 244, 244);

  .loader {
    position: fixed;
    top:55%;
    left: 50%;
  }
  
  .search-input {
    display: flex;
    width: 100%;
    margin-top: 3%;
    margin-bottom: 2%;
    height: 5%;
    align-items: center;

    .MuiFilledInput-input {
      width: 35rem;
    }

    .MuiButton-root {
      margin-left: 3%;
    }
  }

  .MuiContainer-root {
    height: 85%;

    .MuiBox-root.css-12zvdn5 {
      background-color: white;
      margin-top: 5%;
      height: 90%;
      overflow: auto;
      
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        margin-left: 6%;
        margin-top: 5%;
  
        li {
          list-style: none;
          margin-right: 4.5%;
          margin-bottom: 3%;
          width: 20%;
        }
      }
    }
  }

}