@import "../../global.scss";

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: white;
  width: 100%;
  top: 0;
  z-index: 2;

  ul{
    display: flex;
    justify-content: center;

    li{
      list-style: none;
      white-space: pre;
      text-align: center;
      margin-right: 20%;

      a {
        text-decoration: none;
        color: $mainColor;
      }
    }
  }

  .logged {
    display: flex;
    align-items: center;
    position: fixed;
    right: 0;
    margin-right: 2%;
    
    li {
      margin-right: 10%;
    }
  }

  .login-menu {
    position: fixed;
    right: 0;
    margin-right: 2%;
  }

  img {
    position: fixed;
    left: 0;
    height: 40%;
  }
}
