.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
    width: 60%;
    
    .MuiPaper-root {
      display: flex;
      padding: 1.5%;
      flex-direction: column;
      background-color: rgb(239, 236, 236);

      .MuiTypography-root {
        margin-bottom: 1.5%;
      }
    }
  }

  .share-link {
    cursor: pointer;
    margin-bottom: 1%;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    width: 70%;

    li {
      list-style: decimal;
      list-style-position: inside;
    }
  }
}