.signup {
  display: flex;
  margin-top: 7%;
  flex-direction: column;

  .page-title {
    align-self: center;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5%;
    
    button {
      margin-top: 1.5%;
    }
  }
}