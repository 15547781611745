.my-lists {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7%;

  .loader {
    position: fixed;
    top:55%;
    left: 50%;
  }

  ul {
    list-style-type: none;
    width: 100%;

    li {
      margin-bottom: 7%;
      display: flex;
      align-items: center;

      .index-list-item {
        margin-right: 2%;
      }

      .list-buttons {
        display: flex;
        flex-direction: column;
      }
      
      button {
        background-color: white;
        cursor: pointer;
        border: none;
        margin-top: 15%;
        margin-bottom: 15%;
      }
    }
  }
}