.results-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .MuiBox-root {
    width: 100%;
  }

  img {
    object-fit: contain;
    width: 100%;
    cursor: pointer;
  }

  h6 {
    text-align: center;
    font-size: small;
  }

  .add-movie {
    display: none;
    top: 35%;
    cursor: pointer;
    position: absolute;

    .MuiSvgIcon-root{
      font-size: xxx-large;
      outline: 1rem;
    }
  }

  &:hover {
    img {
      background-color: black;
      opacity: 0.4;
    }

    .add-movie {
      display: flex;
    }
  }
}