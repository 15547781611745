.new-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7%;

  .page-title {
    align-self: center;
  }

  .create-list-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2%;

    .public-toggle {
      margin-top: 1.5%;
      margin-bottom: 2%;
    }
  }
}