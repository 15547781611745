.edit-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7%;

  form {
    display: flex;
    flex-direction: column;
    width: 35%;

    .public-toggle {
      margin-top: 1.5%;
      margin-bottom: 2%;
      align-self: center;
    }

    .MuiButton-root {
      align-self: center;
      width: 30%;
    }
  }

  .add-movies-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    width: 15%;

    .add-icon {
      margin-right: 5%;
      z-index: 0;
    }
  }

  ul {
    list-style: decimal;
    list-style-position: inside;
    width: 70%;

    li {
      display: flex;

      .movie-item {
        margin-right: 2%;
        width: 90%;
      }

      button {
        background-color: transparent;
        border: 0;
        cursor: pointer;
      }

      .MuiSvgIcon-root {
        font-size: xx-large;
      }
    }
  }  

  .list-buttons {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .add-section-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 3%;
    right: 5%; 
    
    .add-icon {
      margin-top: 2%;
    }
  }
}