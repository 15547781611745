.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7%;

  form {
    display: flex;
    margin-top: 2%;
    align-items: center;
    flex-direction: column;
    width: 70%;

    .MuiButton-root {
      align-self: center;
      width: 10%;
      margin-top: 2%;
    }
  }
}